import React, { useEffect } from "react";
import Banner from "./Banner/Banner";
import Home_About_Us from "./Home_About_Us/Home_About_Us";
import How_to_Get_Started from "./How_to_Get_Started/How_to_Get_Started";
import TrustedPlatform from "../AboutUs/TrustedPlatform/TrustedPlatform";
import DownloadTheApp from "../AboutUs/DownloadTheApp/DownloadTheApp";
import Why_Choose_Us from "./Why_Choose_Us/Why_Choose_Us";
import { useLocation } from "react-router-dom";

function Home({ howToGetStartedRef }) {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToSection && howToGetStartedRef.current) {
      howToGetStartedRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location, howToGetStartedRef]);

  return (
    <>
      <section className="Home">
        <Banner />
        <Home_About_Us />
        <div id="How_to_Get_Started" ref={howToGetStartedRef}>
          <How_to_Get_Started />
        </div>
        <TrustedPlatform />
        <Why_Choose_Us />
        <DownloadTheApp />
      </section>
    </>
  );
}

export default Home;
