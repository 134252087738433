import React from "react";
import "./Footer.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function Footer({ scrollToSection }) {
  const location = useLocation();
  return (
    <>
      <section className="Footer">
        <div className="container">
          <div className="footer-logo-holder">
            <div className="row">
              <div className="col-md-12">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="logo-holder">
                      <Link to={"/"}>
                        <img
                          className="logo-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/Assets/Images/Header/Jgm.png"
                          }
                          alt="JGM_logo"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-menu">
            <ul>
              <li>
                <div className="menu-holder">
                  <Link to={"/"}>
                    <p>Home</p>
                  </Link>
                </div>
              </li>

              <li>
                <div className="menu-holder">
                  <Link to={"/about-us"}>
                    <p>About Us</p>
                  </Link>
                </div>
              </li>

              <li>
                <div className="menu-holder">
                  <p onClick={scrollToSection}>How it Works</p>
                </div>
              </li>

              <li>
                <div className="menu-holder">
                  <Link to={"/contact-us"}>
                    <p>Contact Us</p>
                  </Link>
                </div>
              </li>

              <li>
                <div className="menu-holder">
                  <Link to={"/privacy-policy"}>
                    <p>Privacy Policy</p>
                  </Link>
                </div>
              </li>

              <li>
                <div className="menu-holder">
                  <Link to={"/terms-condition"}>
                    <p>Terms & Conditions</p>
                  </Link>
                </div>
              </li>

              <li>
                <div className="menu-holder">
                  <Link to={"/refund-policy"}>
                    <p>Refund Policy</p>
                  </Link>
                </div>
              </li>
            </ul>
          </div>

          <div className="footer-info-holder">
            <div className="row address-holder info-content-holder">
              <div className="col-md-6">
                <div className="info-holder">
                  <div className="icon-holder">
                    <FontAwesomeIcon
                      className="footer-icon"
                      icon={faLocationDot}
                    />
                  </div>
                  <p>
                    Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Pune -
                    411038
                  </p>
                </div>
              </div>
            </div>

            <div className="row contact-info-holder info-content-holder">
              <div className="col-md-6">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="info-holder">
                      <div className="icon-holder">
                        <FontAwesomeIcon
                          className="footer-icon"
                          icon={faPhone}
                        />
                      </div>
                      <p> +91 9876543210</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="info-holder">
                      <div className="icon-holder">
                        <FontAwesomeIcon
                          className="footer-icon"
                          icon={faEnvelope}
                        />
                      </div>
                      <p>jgmhelp@qmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-social-icon-holder">
              <div className="icon-holder">
                <Link to={"https://www.facebook.com/"} target="_black">
                  <FontAwesomeIcon className="footer-icon" icon={faFacebookF} />
                </Link>
              </div>

              <div className="icon-holder">
                <Link to={"https://www.linkedin.com/"} target="_black">
                  <FontAwesomeIcon
                    className="footer-icon"
                    icon={faLinkedinIn}
                  />
                </Link>
              </div>

              <div className="icon-holder">
                <Link to={"https://x.com/"} target="_black">
                  <FontAwesomeIcon className="footer-icon" icon={faTwitter} />
                </Link>
              </div>

              <div className="icon-holder">
                <Link to={"https://www.youtube.com/"} target="_black">
                  <FontAwesomeIcon className="footer-icon" icon={faYoutube} />
                </Link>
              </div>
            </div>
          </div>

          <div className="footer-copyright-holder">
            <p>© 2024 JGM. All Rights Reserved. Developed by Profcyma</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
