import React, { useEffect } from 'react'
import './PrivacyPolicy.css'
import { Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
const PrivacyPolicy = ({ howToGetStartedRef }) => {
    const location = useLocation();
    // Scroll to the top when the component mounts
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    return (
        <>
            <section className='privacy-policy'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center mb-4'>
                            <h2>Privacy Policy</h2>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>1. Introduction</h3>
                            <p>Welcome to JGM Finance. We are committed to protecting your personal information and your right to privacy.
                                This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.jgmfinance.com],
                                use our services, or participate in our multilevel marketing programs.
                            </p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>2. Information We Collect</h3>
                            <p>Personal Information: When you sign up as a distributor or customer, we may collect personal information such as your name, email address, phone number,
                                postal address, and financial details. </p>
                            <p>Account Information: We collect information related to your account activity, including transactions, purchase history, and MLM network information.</p>
                            <p>Technical Data: We collect data related to your device and usage, such as IP address, browser type, operating system, and browsing behavior.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>3. How We Use Your Information</h3>
                            <p>   We use your personal information for the following purposes:<br />
                                To process and manage your account and transactions.<br />
                                To communicate with you about updates, promotions, and other information related to our services.<br />
                                To improve our website, services, and customer support.<br />
                                To comply with legal obligations and resolve disputes.<br />
                            </p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>4. Sharing Your Information</h3>
                            <p> We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:
                                With Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processors and
                                customer support.
                            </p>
                            <p>For Legal Reasons: We may disclose your information if required by law or in response to valid legal requests.</p>
                            <p>Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>5. Data Security</h3>
                            <p> We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no
                                security system is impenetrable, and we cannot guarantee the security of your data.
                            </p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>6. Your Choices</h3>
                            <p>   Access and Update: You can access and update your personal information by logging into your account or contacting us directly.<br />
                                Opt-Out: You can opt-out of receiving promotional communications from us by following the unsubscribe instructions in the emails or contacting us.

                            </p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>7. Cookies and Tracking Technologies</h3>
                            <p>  We use cookies and similar technologies to collect information about your browsing behavior and enhance your experience on our website. You can manage your
                                cookie preferences through your browser settings.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>8. Children's Privacy</h3>
                            <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware
                                of such information, we will take steps to delete it.
                            </p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>9. Changes to This Privacy Policy</h3>
                            <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our website and updating the
                                effective date. Your continued use of our services after the changes indicate your acceptance of the revised policy.</p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PrivacyPolicy
