import React from "react";
import "./Header.css";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function Header({ scrollToSection }) { // Accept scrollToSection as a prop
  const location = useLocation();

  return (
    <>
      <section className="Header">
        <div className="container-fluid ">
          <div className="Header_holder">
            <div className="row">
              <div className="col-md-5  col-sm-5 col-6  order-1">
                <div className="icon-text-holder">
                  <div className="icon-holder">
                    <FontAwesomeIcon className="icon" icon={faPhone} />
                  </div>
                  <p>+91 9876543210</p>
                </div>
              </div>

              <div className="col-md-2 col-sm-2 col-6  order-md-2 order-sm-2 order-3">
                <div className="logo-holder">
                  <Link to={"/"}>
                    <img
                      className="logo-img"
                      src={
                        process.env.PUBLIC_URL + "/Assets/Images/Header/Jgm.png"
                      }
                      alt="JGM_logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-sm-12 col-6 order-4  mobile-view-toggle-menubar">
                <div className="menu-header-holder">
                  <div className="">
                    <Navbar expand="lg" className="menu-header">
                      <div className="">
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                          <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: "100px" }}
                            navbarScroll
                          >
                            <Nav.Link
                              as={Link}
                              to={"/"}
                              className={
                                location.pathname === "/" ? "active" : ""
                              }
                            >
                              HOME
                            </Nav.Link>

                            <Nav.Link
                              as={Link}
                              to={"/about-us"}
                              className={
                                location.pathname === "/about-us"
                                  ? "active"
                                  : ""
                              }
                            >
                              ABOUT US
                            </Nav.Link>
                            <Nav.Link
                              onClick={scrollToSection} // Add onClick event to scroll to the section
                            >
                              HOW IT WORKS
                            </Nav.Link>
                            <Nav.Link
                              as={Link}
                              to={"/contact-us"}
                              className={
                                location.pathname === "/contact-us"
                                  ? "active"
                                  : ""
                              }
                            >
                              CONTACT US
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </div>
                    </Navbar>
                  </div>
                </div>
              </div>

              <div className="col-md-5 col-sm-5 col-6 order-md-3 order-sm-3 order-2">
                <div className="social-icon-holder">
                  <div className="icon-holder">
                    <Link to={"https://www.facebook.com/"} target="_black">
                      <FontAwesomeIcon className="icon" icon={faFacebookF} />
                    </Link>
                  </div>

                  <div className="icon-holder">
                    <Link to={"https://www.linkedin.com/"} target="_black">
                      <FontAwesomeIcon className="icon" icon={faLinkedinIn} />
                    </Link>
                  </div>

                  <div className="icon-holder">
                    <Link to={"https://x.com/"} target="_black">
                      <FontAwesomeIcon className="icon" icon={faTwitter} />
                    </Link>
                  </div>

                  <div className="icon-holder">
                    <Link to={"https://www.youtube.com/"} target="_black">
                      <FontAwesomeIcon className="icon" icon={faYoutube} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="menu-header-holder desktop-view-toggle-menubar">
            <div className="">
              <Navbar expand="lg" className="menu-header">
                <div className="">
                  <Navbar.Toggle aria-controls="navbarScroll" />
                  <Navbar.Collapse id="navbarScroll">
                    <Nav
                      className="me-auto my-2 my-lg-0"
                      style={{ maxHeight: "100px" }}
                      navbarScroll
                    >
                      <Nav.Link
                        as={Link}
                        to={"/"}
                        className={location.pathname === "/" ? "active" : ""}
                      >
                        HOME
                      </Nav.Link>

                      <Nav.Link
                        as={Link}
                        to={"/about-us"}
                        className={
                          location.pathname === "/about-us" ? "active" : ""
                        }
                      >
                        ABOUT US
                      </Nav.Link>
                      <Nav.Link
                        onClick={scrollToSection} // Add onClick event to scroll to the section
                      >
                        HOW IT WORKS
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to={"/contact-us"}
                        className={
                          location.pathname === "/contact-us" ? "active" : ""
                        }
                      >
                        CONTACT US
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </div>
              </Navbar>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
