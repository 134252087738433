import React, { useRef } from "react";
import "./App.css";
import "./index.css";
import { Route, Routes, useNavigate } from "react-router-dom";

import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import AboutUs from "./Components/AboutUs/AboutUs";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Contact from "./Components/Contact/Contact";
import TermsCondition from "./Components/TermsCondition/TermsCondition";
import Refund_Policy from "./Components/Refund_Policy/Refund_Policy";

function App() {
  const navigate = useNavigate();

  const howToGetStartedRef = useRef(null);

  const scrollToSection = () => {
    navigate("/", { state: { scrollToSection: true } });
  };

  return (
    <>
      <Header scrollToSection={scrollToSection} />
      <Routes>
        <Route
          path="/"
          element={<Home howToGetStartedRef={howToGetStartedRef} />}
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/refund-policy" element={<Refund_Policy/>} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer scrollToSection={scrollToSection} />
    </>
  );
}

export default App;
