import React, { useEffect } from 'react'
import AboutBanner from './AboutBanner/AboutBanner'
import AboutPlatform from './AboutPlatform/AboutPlatform'
import KeepCountersec from './KeepCountersec/KeepCountersec'
import TrustedPlatform from './TrustedPlatform/TrustedPlatform'
import OurTeam from './OurTeam/OurTeam'
import DownloadTheApp from './DownloadTheApp/DownloadTheApp'
import { useLocation } from 'react-router-dom'


const AboutUs = ({ howToGetStartedRef }) => {
    const location = useLocation();
    // Scroll to the top when the component mounts
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    return (
        <>
            <AboutBanner />
            <AboutPlatform />
            <KeepCountersec />
            <TrustedPlatform />
            <OurTeam />
            <DownloadTheApp />
        </>
    )
}

export default AboutUs
