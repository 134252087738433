import React from "react";
import "./Why_Choose_Us.css";

function Why_Choose_Us() {
  return (
    <>
      <section className="Why_Choose_Us">
        <div className="container">
          <div className="Why_Choose_Us_holder">
            <div className="row justify-content-between">
              <div className="col-lg-6 col-12 order-lg-1 order-2"> 
                <div className="Why_Choose_Us_content_holder">
                  <div className="heading-holder">
                    <h3 className="main-heading">OUR BENEFITS</h3>
                    <h2 className="heading">Why Choose Us?</h2>
                  </div>

                  <div className="text-holder">
                    <p className="content-text">
                      There are many variations of passages. Lorem Ipsum
                      available, but the majority have suffered.
                    </p>
                  </div>
                </div>

                <div className="Benifits-holder">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 p-0">
                      <div className="content-holder content-holder-first">
                        <div className="row justify-content-center me-0 ms-0">
                          <div className="col-md-9">
                            <div className="heading-holder">
                              <h3>Reliable Platform</h3>
                            </div>

                            <div className="text-holder">
                              <p>
                                Lorem ipsum dolor sit is amet, consectetur
                                notted.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 p-0">
                      <div className="content-holder content-holder-second">
                        <div className="row justify-content-center me-0 ms-0">
                          <div className="col-md-9">
                            <div className="heading-holder">
                              <h3>Quick Help</h3>
                            </div>

                            <div className="text-holder">
                              <p>
                                Lorem ipsum dolor sit is amet, consectetur
                                notted.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 p-0">
                      <div className="content-holder content-holder-third">
                        <div className="row justify-content-center me-0 ms-0">
                          <div className="col-md-9">
                            <div className="heading-holder">
                              <h3>Easy Investment</h3>
                            </div>

                            <div className="text-holder">
                              <p>
                                Lorem ipsum dolor sit is amet, consectetur
                                notted.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 p-0 ">
                      <div className="content-holder content-holder-four">
                        <div className="row justify-content-center me-0 ms-0">
                          <div className="col-md-9">
                            <div className="heading-holder">
                              <h3>Easy Withdrawal</h3>
                            </div>

                            <div className="text-holder">
                              <p>
                                Lorem ipsum dolor sit is amet, consectetur
                                notted.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" col-lg-5 col-12 order-lg-2 order-1">
                <div className="Why_Choose_Us_img_holder">
                  <img
                    className="Why_Choose_Us_img"
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/Images/Home/Why_Choose_Us/Why_Choose_Us_img.png"
                    }
                    alt="Why_Choose_Us_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Why_Choose_Us;
