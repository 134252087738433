import React, { useEffect, useRef, useState } from "react";
import "./Contact.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

import { Col, Container, Form, Row } from "react-bootstrap";
import {
  faAngleDown,
  faCircleXmark,
  faEnvelope,
  faLocationDot,
  faMapLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import SuccesfullModal from "./SuccesfullModal/SuccesfullModal";

function Contact({ howToGetStartedRef }) {
  const [modalShow, setModalShow] = useState(false);

  const location = useLocation();
  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  const [phoneValue, setPhoneValue] = useState("");
  const [showMap, setShowMap] = useState(false);
  const mapRef = useRef(null);

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  const handleClickOutside = (event) => {
    if (mapRef.current && !mapRef.current.contains(event.target)) {
      setShowMap(false);
    }
  };

  useEffect(() => {
    if (showMap) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMap]);

  return (
    <>
      <section className="Contact">
        <div className={`contact_section`}>
          <Container>
            <div className="contact_main">
              <div className="heading-holder">
                <h2 className="heading">Contact Us</h2>
              </div>
      
              <div className="contact-form-holder">
                <Row className="justify-content-center">
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                    <div className="map-form-holder">
                      <Row className="form-holder-contact me-0 ms-0">
                        <Col
                          xxl={8}
                          xl={8}
                          lg={7}
                          md={6}
                          sm={12}
                          className="p-0"
                        >
                          <div className="leftdivv">
                            <Form>
                              <Form.Group
                                className="mb-4"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Your Name"
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-4"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-4"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Subject"
                                />
                              </Form.Group>
                              <PhoneInput
                                className="mb-4"
                                country={"in"}
                                value={phoneValue}
                                onChange={setPhoneValue}
                                 placeholder="Enter Mobile Number"
                                inputProps={{
                                  name: "phone",
                                  required: true,
                                  autoFocus: true,
                                  placeholder: "Enter Mobile Number",
                                }}
                              />
                              <Form.Group
                                className="mb-4"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Form.Control
                                  as="textarea"
                                  rows={1}
                                  placeholder="Your Message"
                                />
                              </Form.Group>
                            </Form>
                            <div className="btndiv">
                              <button className="btnnnn" onClick={() => setModalShow(true)}>Send Message</button>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xxl={4}
                          xl={4}
                          lg={5}
                          md={6}
                          sm={12}
                          className="p-0"
                        >
                          <div className="leftdivv1">
                            <div className="d-flex align-items-center side-section">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon icon={faPhone} />
                                </div>
                              </div>
                              <p className="cont_text mb-0 ms-2">01123968786</p>
                            </div>
                            <div className="d-flex align-items-center side-section">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                              </div>
                              <p className="cont_text mb-0 ms-2">
                                jgmhelp@qmail.com
                              </p>
                            </div>
                            <div className="d-flex align-items-center side-section">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon icon={faLocationDot} />
                                </div>
                              </div>
                              <p className="cont_text mb-0 ms-2">
                                Akshya Nagar 1st Block 1st Cross, Rammurthy
                                Nagar, Pune - 411038
                              </p>
                            </div>
                            <div className="d-flex align-items-center side-section">
                              <div>
                                <div className="fonticndiv">
                                  <FontAwesomeIcon icon={faMapLocationDot} />
                                </div>
                              </div>
                              <p
                                className="cont_text mb-0 ms-2 text-decoration-underline cursor-pointer"
                                onClick={toggleMap}
                              >
                                Find Us on map
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {showMap && (
                        <div className="close-btn" onClick={() => setShowMap(false)}>
                          <FontAwesomeIcon icon={faCircleXmark} />
                        </div>
                      )}

                      <div
                        ref={mapRef}
                        className={`gmap_canvas swing-top-fwd ${
                          showMap ? "show" : ""
                        }`}
                      >
                        <iframe
                          className="gmap_iframe"
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                          src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        ></iframe>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <SuccesfullModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={"Message sent successfully"}
      />
    </>
  );
}

export default Contact;
