import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { Container, Row } from "react-bootstrap";
const AboutBanner = () => {
 
  return (
    <>
      {/* <section className='about-banner'>
                <Container>
                    <Row>
                        <div className='heading-holder'>
                            <h2>About Us</h2>
                            <h3>Your Partner in Growth</h3>
                        </div>
                    </Row>
                </Container>
            </section> */}
      <section className="Banner-holder">
        {/* <div className="menu-header-holder">
          <div className="">
            <Navbar expand="lg" className="menu-header">
              <div className="">
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: "100px" }}
                    navbarScroll
                  >
                    <Nav.Link
                      as={Link}
                      to={"/"}
                      className={location.pathname === "/" ? "active" : ""}
                    >
                      HOME
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to={"/about-us"}
                      className={
                        location.pathname === "/about-us" ? "active" : ""
                      }
                    >
                      ABOUT US
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to={"/how-it-work"}
                      className={
                        location.pathname === "/how-it-work" ? "active" : ""
                      }
                    >
                      HOW IT WORKS
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to={"/contact-us"}
                      className={
                        location.pathname === "/contact-us" ? "active" : ""
                      }
                    >
                      CONTACT US
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Navbar>
          </div>
        </div> */}
        <div className="Home-banner">
          <div className="Banner">
            <div className="banner-image-holder">
              <img
                className="banner-image"
                src={
                  process.env.PUBLIC_URL +
                  "/Assets/Images/about/banner-about-2.png"
                }
                alt="banner-image"
              />
            </div>
            <div className="banner-text-holder">
              <h3>
                {" "}
                <span>About Us</span>
                <br /> Your Partner in Growth{" "}
              </h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutBanner;
