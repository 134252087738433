import React from "react";
import "./TrustedPlatform.css";
import { Col, Container, Row } from "react-bootstrap";
const TrustedPlatform = () => {
  return (
    <>
      <section className="Trusted-Platform">
        <Container>
          <Row>
            <Col lg={5} md={6}>
              <div className="heading-holder">
                <h2 className="main-heading ">TRUSTED PLATFORM </h2>
                <h3 className="heading ">Most of the People Trust on Us</h3>
              </div>
              <div className="text-holder">
                <p className="content-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type & scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </Col>
            <Col lg={5} md={6} className="ms-auto">
              <div className="solution-box mb-3">
                <div className="solution-box-text-holder">
                  <h3>Solutions </h3>
                  <p>for small or big, all you needs</p>
                </div>
                <div className="circle-box text-center">
                  <img
                    className="circle-image "
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/Images/about/solutions.png"
                    }
                    alt="circle-image"
                  />
                </div>
              </div>
              <div className="solution-box mb-3">
                <div className="solution-box-text-holder">
                  <h3>Get a Help </h3>
                  <p>and lead a way to your progress</p>
                </div>
                <div className="circle-box text-center">
                  <img
                    className="circle-image "
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/Images/about/social-support.png"
                    }
                    alt="circle-image"
                  />
                </div>
              </div>
              <div className="solution-box mb-3">
                <div className="solution-box-text-holder">
                  <h3>Instant </h3>
                  <p>help within a matter of moments</p>
                </div>
                <div className="circle-box text-center">
                  <img
                    className="circle-image "
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/Images/about/instant.png"
                    }
                    alt="circle-image"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TrustedPlatform;
