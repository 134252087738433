import React from "react";
import "./Home_About_Us.css";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

function Home_About_Us() {
  return (
    <>
      <section className="Home_About_Us">
        <div className="container">
          <div className="Home_About_Us_holder">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12  order-sm-1 order-2">
                <div className="Home_About_Us_content_holder">
                  <div className="heading-holder">
                    <h3 className="main-heading">ABOUT US</h3>
                    <h2 className="heading">
                      A Platform for a Better Tomorrow
                    </h2>
                  </div>

                  <div className="text-holder">
                    <p className="content-text">
                      We are not just an online version of any Business market,
                      but also, the reflection of each and every MLM business.
                      We are an international financial company engaged in
                      investment activities, which are related to MLM on
                      financial markets by qualified professional traders.
                    </p>
                  </div>

                  <div className="btn-holder">
                    <Link to={"/about-us"}>
                      <Button className="know-more-btn" type="Button">
                        KNOW MORE
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 order-sm-2 order-1">
                <div className="Home_About_Us_img_holder">
                  <img
                    className="Home_About_Us_img"
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/Images/Home/Home_AboutUs/Home_about_us_img.png"
                    }
                    alt="Home_About_Us_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home_About_Us;
