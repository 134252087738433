import React from 'react'
import './AboutPlatform.css'
import { Container, Row } from 'react-bootstrap'
const AboutPlatform = () => {
    return (
        <>
            <section className='about-platform'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center'>
                            <h2>ABOUT US</h2>
                            <h3>A Platform for a Better Tomorrow</h3>
                            <p>We are not just an online version of any Business market, but also, the reflection of each and every MLM business.
                                We are an international financial company engaged in investment activities, which are related to MLM on financial
                                markets by qualified professional traders. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
                                typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default AboutPlatform
