import React, { useState } from "react";
import "./DownloadTheApp.css";
import { Col, Container, Row } from "react-bootstrap";
const DownloadTheApp = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <section className="Download-App">
        <Container>
          <Row>
            <Col lg={5} md={6}>
              <div className="heading-holder mt-5">
                <h2>Download the App</h2>
                <div className="border-line my-3"></div>
              </div>
              <div className="text-holder">
                <p className="content-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type & scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div className="button-holder mt-5">
                <img
                  className="app-image me-3"
                  src={
                    process.env.PUBLIC_URL + "/Assets/Images/about/play-btn.png"
                  }
                  alt="app-image"
                />
                <img
                  className="app-image "
                  src={
                    process.env.PUBLIC_URL + "/Assets/Images/about/app-btn.png"
                  }
                  alt="app-image"
                />
              </div>
            </Col>
            <Col lg={5} md={6} className="mx-auto">
              <div
                className="mobileimgdiv "
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    (isHovered
                      ? "/Assets/Images/about/img-3.png"
                      : "/Assets/Images/about/img-4.png")
                  }
                  className="mobileimg"
                  alt="mobileimg"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DownloadTheApp;
