import React, { useEffect, useState } from "react";
import "./Banner.css";
import CountUp from 'react-countup';
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function Banner() {

  return (
    <section className="Banner-holder">
      
      <div className="Home-banner">
        <div className="Banner">
          <div className="banner-image-holder">
            <img
              className="banner-image"
              src={
                process.env.PUBLIC_URL +
                "/Assets/Images/Home/Banner/Banner_img.png"
              }
              alt="banner-image"
            />
          </div>
          <div className="banner-text-holder">
            <h3>
              All New Platform for <br /> Easy Instant Growth!
            </h3>
          </div>
        </div>
      </div>
      <div className="counter-holder">
      <div className="row justify-content-end me-0 ms-0">
        <div className="col-md-9 col-9">
          <div className="row">
            <div className="col-md-4 col-4">
              <div className="row">
                <div className="col-md-5 col-12">
                  <div className="counter-heading-holder">
                    <h3>Happy Consumers</h3>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="count-holder">
                    <h3>
                      <CountUp end={15} duration={2.5} />K
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-4">
              <div className="row">
                <div className="col-md-5 col-12">
                  <div className="counter-heading-holder">
                    <h3>Helped Consumers</h3>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="count-holder">
                    <h3>
                      <CountUp end={50} duration={2.5} />K
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-4">
              <div className="row">
                <div className="col-md-5 col-12">
                  <div className="counter-heading-holder">
                    <h3>Happy Consumers</h3>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="count-holder">
                    <h3>
                      <CountUp end={15} duration={2.5} />K
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
}

export default Banner;
