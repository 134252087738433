import React from 'react';
import './KeepCountersec.css';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';

const KeepCountersec = () => {
    const counterData = [
        {
            backgroundClass: 'counter-box',
            overlayClass: 'overlay1',
            endValue: 15,
            label: 'Happy<br /> Consumers'
        },
        {
            backgroundClass: 'counter-box2',
            overlayClass: 'overlay2',
            endValue: 50,
            label: 'Happy<br /> Consumers'
        },
        {
            backgroundClass: 'counter-box3',
            overlayClass: 'overlay3',
            endValue: 15,
            label: 'Happy<br /> Consumers'
        }
    ];

    return (
        <>
            <section className='keep-counter-section'>
                <Container>
                    <Row>
                        <div className='heading-name text-center'>
                            <h2>This keeps us up</h2>
                        </div>
                        {counterData.map((item, index) => (
                            <Col md={6} lg={4} key={index}>
                                <div className={item.backgroundClass}>
                                    <div className='total-holder'>
                                        <p className='me-2' dangerouslySetInnerHTML={{ __html: item.label }}></p>
                                        <h4><CountUp start={0} end={item.endValue} uration={2.5} decimals={0} />  K</h4>
                                    </div>
                                    <div className={item.overlayClass}></div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default KeepCountersec;
