import React from 'react'
import './OurTeam.css'
import { Col, Container, Row } from 'react-bootstrap'
import { faFacebookF, faLinkedinIn, faTwitter, } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const OurTeam = () => {
    return (
        <>
            <section className='our-team '>
                <Container>
                    <Row>
                        <div className='heading-holder mb-md-5 mb-sm-3 mb-3'>
                            <h2>OUR PILLARS</h2>
                            <h3>Our Team</h3>
                        </div>
                        <Col lg={4} md={6} sm={6}>
                            <div className='team-holder-box mb-3'>
                                <img className="team-img" src={process.env.PUBLIC_URL + "/Assets/Images/about/image-1.png"} alt="team-img" />
                                <div className='text-holder ps-4'>
                                    <h4>Andrew Max Fetcher</h4>
                                    <h5>CEO</h5>

                                </div>
                                <div className="social-icon-holder">
                                    <div className="icon-holder">
                                        <FontAwesomeIcon className="icon" icon={faFacebookF} />
                                    </div>

                                    <div className="icon-holder">
                                        <FontAwesomeIcon className="icon" icon={faLinkedinIn} />
                                    </div>

                                    <div className="icon-holder">
                                        <FontAwesomeIcon className="icon" icon={faTwitter} />
                                    </div>
                                </div>
                                <div className='overlay-color'></div>

                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} >
                            <div className='team-holder-box mb-3'>
                                <img className="team-img" src={process.env.PUBLIC_URL + "/Assets/Images/about/image-2.png"} alt="team-img" />
                                <div className='text-holder ps-4'>
                                    <h4>Mike Holder</h4>
                                    <h5>CEO</h5>

                                </div>
                                <div className="social-icon-holder">
                                    <div className="icon-holder">
                                        <FontAwesomeIcon className="icon" icon={faFacebookF} />
                                    </div>

                                    <div className="icon-holder">
                                        <FontAwesomeIcon className="icon" icon={faLinkedinIn} />
                                    </div>

                                    <div className="icon-holder">
                                        <FontAwesomeIcon className="icon" icon={faTwitter} />
                                    </div>
                                </div>
                                <div className='overlay-color'></div>

                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6}>
                            <div className='team-holder-box mb-3'>
                                <img className="team-img" src={process.env.PUBLIC_URL + "/Assets/Images/about/image-3.png"} alt="team-img" />
                                <div className='text-holder ps-4'>
                                    <h4>Andrew Max Fetcher</h4>
                                    <h5>CEO</h5>

                                </div>
                                <div className="social-icon-holder">
                                    <div className="icon-holder">
                                        <FontAwesomeIcon className="icon" icon={faFacebookF} />
                                    </div>

                                    <div className="icon-holder">
                                        <FontAwesomeIcon className="icon" icon={faLinkedinIn} />
                                    </div>

                                    <div className="icon-holder">
                                        <FontAwesomeIcon className="icon" icon={faTwitter} />
                                    </div>
                                </div>
                                <div className='overlay-color'></div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OurTeam
