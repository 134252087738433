import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
const Refund_Policy = ({ howToGetStartedRef }) => {
  const location = useLocation();
  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <section className="privacy-policy">
        <Container>
          <Row>
            <div className="heading-holder text-center mb-4">
              <h2>Refund Policy</h2>
            </div>
            <div className="text-holder mb-3">
              <p>
                At JGM HELPHUB, the online helping platform, we are committed to
                providing our users with a transparent and secure platform to
                give and receive financial help directly. While we primarily
                facilitate the direct transfer of financial help for our
                consumers from other consumers without selling physical goods,
                we understand the importance of having clear policies in place,
                especially regarding the services we charge for. This policy
                outlines the circumstances under which we will issue refunds to
                our users.
              </p>
              <p>
                <b>This refund policy covers the following paid services:</b>
              </p>
              <ul className="list-holder">
                <li>
                  <p>Consumer ID Transfer</p>
                </li>
                <li>
                  <p>Consumer ID Updation</p>
                </li>
              </ul>
            </div>
            <div className="text-holder mb-3">
              <h3>Refund Eligibility</h3>
              <ul className="list-holder">
                <li>
                  <p>
                    <strong>Incorrect Transactions:</strong> If a service fee is
                    charged due to an incorrect transaction initiated by our
                    platform's system, the user is eligible for a full refund of
                    the service fee charged.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Service Failure:</strong> If a paid service (e.g.,
                    ID Updation) is not completed due to a technical error or
                    system failure on our part, users are eligible for a full
                    refund.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cancellation:</strong> If a user requests the
                    cancellation of a service (e.g., ID Updation) before the
                    service is executed, they may be eligible for a full or
                    partial refund depending on the stage of service processing.
                  </p>
                </li>
              </ul>
            </div>
            <div className="text-holder mb-3">
              <h3>Refund Process</h3>
              <ol className="list-holder">
                <li>
                  <p>
                    <strong>Request Submission:</strong> Users must submit a
                    refund request through our designated customer support
                    channels (Consumer care email ID). The request should
                    include the user Consumer ID, transaction details, and a
                    brief explanation of the reason for the refund.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Review Period:</strong> Refund requests will be
                    reviewed within 7 business days. We may contact the user for
                    additional information if necessary.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Refund Issuance:</strong> Once a refund request is
                    approved, the refund will be processed back to the original
                    payment method within 5-10 business days.
                  </p>
                </li>
              </ol>
            </div>
            <div className="text-holder mb-3">
              <h3>Exceptions</h3>
              <ul className="list-holder">
                <li>
                  <p>
                    No refunds will be provided for services that have been
                    fully delivered and used as intended.
                  </p>
                </li>
                <li>
                  <p>
                    Refunds are not available for dissatisfaction with financial
                    help amounts between consumers, as these transactions are
                    between consumers, and no money goes through JGM HELPHUB.
                  </p>
                </li>
              </ul>
            </div>
            <div className="text-holder mb-3">
              <h3>Contact Information</h3>
              <p>
                For refund requests or questions regarding our refund policy,
                please contact our customer support team at{" "}
                <Link
                  className="link-text"
                  to="https://mail.google.com/"
                  target="_blank"
                >
                  Jgmhelphub@gmail.com
                </Link>
                .
              </p>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Refund_Policy;
