import React from "react";
import "./How_to_Get_Started.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faUsers,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";

function How_to_Get_Started() {
  return (
    <>
      <section className="How_to_Get_Started">
        <div className="container">
          <div className="How_to_Get_Started_holder">
            <div className="row">
              <div className="col-md-4">
                <div className="heading-holder">
                  <h2 className="heading">
                    How to <br /> Get Started
                  </h2>

                  <h5 className="subheading">
                    Committed to help, uniting together!
                  </h5>
                </div>
              </div>

              <div className="col-md-8">
                <div className="How_to_Get_Started_cards">
                  <div className="row justify-content-between">
                    <div className="col-md-3 col-lg-3 col-sm-3">
                      <div className="card-holder">
                        <div className="img-holder ">
                         <img
                            className="card-image-holder img-orignal"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/How_to_Get_Started/card-bg-orignal.png"
                            }
                            alt="card-image"
                          />

                          <img
                            className="card-image-holder img-hover"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/How_to_Get_Started/card-bg-img.png"
                            }
                            alt="card-image"
                          />

                          <div className="icon-holder">
                            <FontAwesomeIcon
                              className="icon"
                              icon={faUserPlus}
                            />
                          </div>
                          <div className="after-border"></div>
                        </div>

                        <div className="text-holder">
                          <p>Create an Account</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-lg-3 col-sm-3">
                      <div className="card-holder ">
                        <div className="img-holder ">
                        <img
                            className="card-image-holder img-orignal"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/How_to_Get_Started/card-bg-orignal.png"
                            }
                            alt="card-image"
                          />

                          <img
                            className="card-image-holder img-hover"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/How_to_Get_Started/card-bg-img.png"
                            }
                            alt="card-image"
                          />

                          <div className="icon-holder">
                            <FontAwesomeIcon className="icon" icon={faUsers} />
                          </div>

                          <div className="after-border"></div>
                        </div>
                        <div className="text-holder">
                          <p>Invite More People</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-lg-3 col-sm-3">
                      <div className="card-holder">
                        <div className="img-holder">
                          <img
                            className="card-image-holder img-orignal"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/How_to_Get_Started/card-bg-orignal.png"
                            }
                            alt="card-image"
                          />

                          <img
                            className="card-image-holder img-hover"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/How_to_Get_Started/card-bg-img.png"
                            }
                            alt="card-image"
                          />

                          <div className="icon-holder">
                            <FontAwesomeIcon className="icon" icon={faWallet} />
                          </div>
                        </div>
                        <div className="text-holder">
                          <p>Get Commission</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default How_to_Get_Started;
